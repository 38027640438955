<template>
  <div class="account-page">
    <template v-if="!isShow && !link">
      <b-spinner/>
    </template>
    <profile-menu/>
    <div class="iframe-wrap">
      <iframe
        v-if="isShow && link"
        ref="iframe"
        :src="iframeSrc"
        width="100%"
        height="100%"
        frameborder="0"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { amsClient } from '@/service/ams';
  import ProfileMenu from '@/components/profileMenu/profileMenu';
  import Vue from 'vue';
  import { $stats } from '../../plugins/analytics/vue-analytics-plugin';

  export default {
    name: 'AccountContainer',
    components:{
      ProfileMenu,
    },
    data() {
      return {
        isShow: false,
        link: '',
        shopifyApiEndpoint: null,
      };
    },
    computed: {
      ...mapGetters('checkout', {
        getAvailableCheckoutId: 'getAvailableCheckoutId',
      }),
      iframeSrc() {
        return this.link;
      },
    },
    watch: {
      isShow(val) {
        this.$nextTick(() => {
          Vue.prototype.$accountIframeRef = val ? this.$refs.iframe : null;
        });
      },
    },
    created() {
      $stats.send('page_opened', {
        page_type: 'my-profile',
      });
      this.$watch(
        () => this.$route.params,
        ({ param }) => this.setData(param),
        { immediate: true },
      );

      this.shopifyApiEndpoint = amsClient.get('conf.portal.marketplace.shopify.shopify_api_endpoint');
    },
    methods: {
      async setData(param) {
        this.isShow = false;
        this.link = '';
        let pageLink = null;

        switch (param) {
          case 'orders':
            pageLink = await amsClient.get('conf.portal.marketplace.shopify.my_orders_url');
            break;
          case 'billing-address':
            pageLink = await amsClient.get('conf.portal.marketplace.shopify.billing_adress_url');
            break;
          case 'settings':
            pageLink = await amsClient.get('conf.portal.marketplace.shopify.account_settings_url');
            break;
          case 'become-seller':
            pageLink = await amsClient.get('conf.portal.marketplace.shopify.become_seller_url');
            break;
          case 'subscription-orders':
            pageLink = await amsClient.get('conf.portal.marketplace.shopify.subscription_orders_url');
            break;
          default:
            pageLink = await amsClient.get('conf.portal.marketplace.shopify.buyer_profile');
        }

        if (param === 'subscription-orders') {
          this.link = pageLink;
          this.isShow = true;
          return;
        }

        if (pageLink) {
          const { pathname } = new URL(pageLink);

          const multipassTokenResponse = await amsClient.callAms('/shopify/token/', {
            query: { redirect_path: pathname, no_checkout: 1 },
          });

          this.link = `${this.shopifyApiEndpoint}/account/login/multipass/${multipassTokenResponse.head.multify_token}`;
          this.isShow = true;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
	.account-page {
    min-height: calc(100vh - 72px);
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;

    @media (max-width: 1025px) {
      min-height: calc(100vh - 55px);
    }
    .spinner-border {
      position: absolute;
      top: calc(50% - 20px);
      left: calc(50% - 20px);
      width: 40px;
      height: 40px;
    }
    .iframe-wrap{
      flex: 1;
    }
  }
</style>
