<template>
  <div
    class="bag-menu"
  >
    <h1 class="title">
      My Account
    </h1>
    <nav class="nav">
      <div v-for="(link, key) in links" :key="key" class="nav-item">
        <router-link :to="`/my-profile/${key}`" class="nav-link" :class="currentState(`/my-profile/${key}`)">
          <span class="nav-link__title">{{ link.name }}</span>
          <i class="nav-link__icon fas fa-chevron-right"/>
        </router-link>
      </div>
    </nav>
  </div>
</template>
<script>
  export default {
    name: 'ProfileMenu',
    data() {
      return {
        links: {
          'settings': { name: "Account Settings" },
          'orders': { name: "Orders" },
          // 'subscription-orders': { name: 'Subscription Orders' },
          'billing-address': { name: "Billing Address" },
        },
      };
    },
    watch: {
      '$route': {
        immediate: true,
        handler(route) {
          this.updateTitle(route);
        },
      },
    },
    methods:{
      updateTitle(route) {
        const { params: { param } } = route;
        if (!param) return;
        const link = this.links[param];
        this.$bus.$emit('updateTitle', link.name);
      },
      currentState(link){
        return this.$route.path === link ? 'active' : null
      },
    },
  };
</script>

<style lang="scss" scoped>
  .bag-menu {
    padding: 35px 20px 30px 30px;
    margin-right: 42px;
    max-width: 349px;
    flex-basis: 349px;
    background: $container-background-color;

    .title{
      margin-bottom: 45px;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
    }

    .nav{
      display: block;
    }

    .nav-item {
      position: relative;
      display: block;
      margin-bottom: 10px;
    }

    .nav-link {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      min-height: 32px;
      padding: 0;
      font-weight: 500;
      font-size: 15px;
      line-height: 32px;
      color: $main-text-color;
      cursor: pointer;

      &:hover {
        color: $main-color;
      }

      &__title {
        flex: 1;
      }

      &__icon {
        visibility: hidden;
        width: 6px;
      }

      &.active {
        .nav-link__title,
        .nav-link__icon{
          color: $main-color;

        }

        .nav-link__icon {
          visibility: visible;
        }
      }
    }

    @media (max-width: 1200px) {
      display: none;
    }
  }
</style>
